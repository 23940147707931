export const fetchCarouselStart = () => ({
    type: 'FETCH_CAROUSEL_START'
})
export const fetchCarouselSuccess = (data) => ({
    type: 'FETCH_CAROUSEL_SUCCESS',
    payload : data

})
export const fetchCarouselFailed = (err) => ({
    type: 'FETCH_CAROUSEL_FAILED',
    payload : err
})