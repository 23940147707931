import { takeLatest, put, call, all } from "redux-saga/effects";
import { fetchCarouselSuccess, fetchCarouselFailed } from "./carousel.actions";
import { BASE_URL } from "../config";

export function* fetchCarouselStartAsync() {
  try {
    const resp = yield fetch(`${BASE_URL}/drupal_version/api/carousel`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/hal+json",
        Authorization: "Basic YWRtaW46JnEzbUhMJSRWU0tSeA==",
      },
    });
    const respJson = yield resp.json();
    yield put(fetchCarouselSuccess(respJson));
  } catch (e) {
    yield put(fetchCarouselFailed(e.message));
  }
}
export function* onFetchCarouselStart() {
  yield takeLatest("FETCH_CAROUSEL_START", fetchCarouselStartAsync);
}
export function* carouselSagas() {
  yield all([call(onFetchCarouselStart)]);
}
