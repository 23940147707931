const INITIAL_STATE = {
    position : [34.0145645,-6.8385563]
}

const positionReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case 'SET_POSITION_TO_MAP':
            return {
                ...state,
                position : action.payload
            }
        default:
            return state
    }
}
export default positionReducer;