import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Facebook from "../assets/Facebook.png";
import LinkedIn from "../assets/LinkedIn.png";
import Instagram from "../assets/Instagram.png";
import Twitter from "../assets/Twitter.png";

const Menu = ({ url }) => {
  return (
    <Container>
      <div className="top">
        <div className="row border-left">
          <h5>Navigation</h5>
          <div className="btns">
            <Link to="/entreprise/">
              <button
                className={
                  url.includes("entreprise")
                    ? "outline-btn active-btn"
                    : "outline-btn"
                }
              >
                Entreprise
              </button>
            </Link>
            <Link to="/particulier/">
              <button
                className={
                  url.includes("particulier")
                    ? "outline-btn active-btn"
                    : "outline-btn"
                }
              >
                Particulier
              </button>
            </Link>
          </div>
        </div>
        <Link to="./">
          <h6>Accueil</h6>
        </Link>
        <Link to="./presentation">
          <h6>Présentation</h6>
        </Link>
        <Link to="./produits">
          <h6>Nos produits</h6>
        </Link>
        <Link to="./">
          <h6>Contactez nous</h6>
        </Link>
        <div className="row">
          <h5>La langue</h5>
          <div className="btns">
            <button className="outline-btn active-btn">Francais</button>
            <button className="outline-btn">Arabe</button>
          </div>
        </div>
        <div className="row icons">
          <a
            href="https://www.facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Facebook} className="icon" />
          </a>
          <a
            href="https://www.facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={LinkedIn} className="icon" />
          </a>
          <a
            href="https://www.facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Instagram} className="icon" />
          </a>
          <a
            href="https://www.facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Twitter} className="icon" />
          </a>
        </div>
      </div>
      <div className="bottom">
        <button className="bottom-btn">
          <svg
            width="21"
            height="26"
            viewBox="0 0 21 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 10.5C0.144251 4.76182 4.76182 0.144251 10.5 0C16.2382 0.144251 20.8557 4.76182 21 10.5C20.9019 12.1965 20.4338 13.851 19.6286 15.3475C19.6041 15.3942 19.5778 15.4408 19.5487 15.4875C19.488 15.5867 18.0448 17.9025 16.3584 20.2592C13.125 24.78 11.7594 25.6667 10.5 25.6667C9.69267 25.6667 7.8015 25.6667 1.45192 15.4875C1.42275 15.4408 1.39592 15.3942 1.37142 15.3475C0.566038 13.8511 0.0979459 12.1965 0 10.5ZM10.5 23.9167C12.2424 23.9167 18.0629 14.5658 18.0629 14.5658C18.7456 13.3114 19.1505 11.9247 19.25 10.5C19.25 5.66751 15.3325 1.75 10.5 1.75C5.66751 1.75 1.75 5.66751 1.75 10.5C1.84957 11.9247 2.25442 13.3114 2.93708 14.5658C2.93708 14.5658 8.77217 23.9167 10.5 23.9167ZM10.5 5.25C7.92267 5.25 5.83333 7.33934 5.83333 9.91667C5.83333 12.494 7.92267 14.5833 10.5 14.5833C13.0773 14.5833 15.1667 12.494 15.1667 9.91667C15.1635 7.34067 13.076 5.25321 10.5 5.25ZM7.58333 9.91667C7.58333 11.5275 8.88917 12.8333 10.5 12.8333C12.1108 12.8333 13.4167 11.5275 13.4167 9.91667C13.4167 8.30584 12.1108 7 10.5 7C8.88917 7 7.58333 8.30584 7.58333 9.91667Z"
              fill="#2584F9"
            />
          </svg>
          <span>Localisation</span>
        </button>
        <button className="bottom-btn">
          <svg
            width="21"
            height="24"
            viewBox="0 0 21 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.2 16.8467H2.1C0.94206 16.8467 0 15.9046 0 14.7467V11.9467C0 11.0341 0.585247 10.2561 1.4 9.96711V9.14667C1.4 4.10317 5.48226 0 10.5 0C15.5177 0 19.6 4.10317 19.6 9.14667V9.96707C20.4148 10.256 21 11.0341 21 11.9467V14.7467C21 15.6592 20.4148 16.4373 19.6 16.7263V18.9933C19.6 20.9232 18.0299 22.4933 16.1 22.4933H12.4796C12.1906 23.3081 11.4126 23.8933 10.5 23.8933C9.34206 23.8933 8.4 22.9513 8.4 21.7933C8.4 20.6354 9.34206 19.6933 10.5 19.6933C11.4126 19.6933 12.1906 20.2786 12.4796 21.0933H16.1C17.2579 21.0933 18.2 20.1513 18.2 18.9933V16.8467H16.8V18.2467H15.4V8.44667H16.8V9.84667H18.2V9.14667C18.2 4.87513 14.7458 1.4 10.5 1.4C6.25422 1.4 2.8 4.87513 2.8 9.14667V9.84667H4.2V8.44667H5.6V18.2467H4.2V16.8467ZM9.8 21.7933C9.8 22.1793 10.114 22.4933 10.5 22.4933C10.886 22.4933 11.2 22.1793 11.2 21.7933C11.2 21.4074 10.886 21.0933 10.5 21.0933C10.114 21.0933 9.8 21.4074 9.8 21.7933ZM19.6 11.9467V14.7467C19.6 15.1326 19.286 15.4467 18.9 15.4467H16.8V11.2467H18.9C19.286 11.2467 19.6 11.5607 19.6 11.9467ZM2.1 11.2467C1.71402 11.2467 1.4 11.5607 1.4 11.9467V14.7467C1.4 15.1326 1.71402 15.4467 2.1 15.4467H4.2V11.2467H2.1Z"
              fill="#2584F9"
            />
          </svg>
          <span> un conseiller</span>
        </button>
        <button className="bottom-btn">
          <svg
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7353 0.415998C13.9966 0.415998 16.0019 0.970666 17.7513 2.08C19.5006 3.168 20.8019 4.53333 21.6553 6.176C22.5086 7.81867 22.9353 9.54667 22.9353 11.36C22.9353 13.5787 22.4553 15.296 21.4953 16.512C20.5353 17.7067 19.3193 18.304 17.8472 18.304C15.6926 18.304 14.2846 17.4613 13.6233 15.776C12.8126 16.7573 11.7566 17.248 10.4553 17.248C9.08992 17.248 7.95925 16.7573 7.06325 15.776C6.18858 14.7733 5.75125 13.5147 5.75125 12C5.75125 10.4853 6.18858 9.23733 7.06325 8.256C7.95925 7.25333 9.08992 6.752 10.4553 6.752C11.6073 6.752 12.5779 7.14667 13.3673 7.936V7.104H16.7913V14.496C16.7913 16.0533 17.4099 16.832 18.6473 16.832C19.5646 16.832 20.3006 16.3733 20.8553 15.456C21.4313 14.5387 21.7193 13.1733 21.7193 11.36C21.7193 8.58667 20.7593 6.25067 18.8393 4.352C16.9193 2.45333 14.5513 1.504 11.7353 1.504C8.79125 1.504 6.30592 2.51733 4.27925 4.544C2.27392 6.54933 1.27125 9.03467 1.27125 12C1.27125 15.0293 2.31658 17.536 4.40725 19.52C6.49792 21.504 9.13258 22.496 12.3113 22.496C14.3166 22.496 16.0446 22.2293 17.4953 21.696V22.848C16.0659 23.3387 14.3379 23.584 12.3113 23.584C8.81258 23.584 5.90058 22.4853 3.57525 20.288C1.24992 18.112 0.08725 15.3493 0.08725 12C0.08725 8.77867 1.19658 6.048 3.41525 3.808C5.65525 1.54667 8.42858 0.415998 11.7353 0.415998ZM11.3193 14.176C11.9166 14.176 12.3966 13.9733 12.7593 13.568C13.1433 13.1627 13.3353 12.64 13.3353 12C13.3353 11.3387 13.1433 10.816 12.7593 10.432C12.3966 10.0267 11.9166 9.824 11.3193 9.824C10.7006 9.824 10.1993 10.0267 9.81525 10.432C9.43125 10.816 9.23925 11.3387 9.23925 12C9.23925 12.64 9.43125 13.1627 9.81525 13.568C10.1993 13.9733 10.7006 14.176 11.3193 14.176Z"
              fill="#2584F9"
            />
          </svg>
          <span>contactez nous</span>
        </button>
      </div>
    </Container>
  );
};

export default Menu;

const Container = styled.div`
  background-color: #fff;
  min-height: calc(100vh - 51px);
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  position: fixed;
  top: 51px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  .top {
    background: #fff;
    width: 100%;
  }
  .row {
    justify-content: space-between;
    width: 100%;
    padding: 0.75em !important;
  }
  .border-left {
    border-left: 6px solid #1bdebb;
  }
  .outline-btn {
    background: #ebfcf9;
    color: #2584f9;
    font-size: 13px;
    font-weight: 400;
    border-radius: 100px;
    padding: 6px 20px;
    margin: 0 0.25em;
    width: 110px;
  }
  .active-btn {
    border: 1px solid #1bdebb;
  }
  h5 {
    color: #2584f9;
    font-size: 14px;
    font-weight: 600;
  }
  h6 {
    color: #2584f9;
    font-size: 14px;
    font-weight: 400;
    margin: 1.5em 1em;
  }
  .icons {
    padding: 1.25em;
  }
  .bottom {
    width: 100%;
    padding: 0 1em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom-btn {
    background: #fff;
    width: calc((100% - 2em) / 3);
    max-width: 120px;
    height: 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 1px solid #2584f9;
    border-bottom: none;
    span {
      font-size: 10px;
      font-weight: 400;
      color: #2584f9;
      margin-top: 0.5em;
    }
  }
  .bottom-btn:first-child {
    border-top-left-radius: 7px;
  }
  .bottom-btn:nth-child(2) {
    border-left: none;
    border-right: none;
  }
  .bottom-btn:last-child {
    border-top-right-radius: 7px;
  }
  .btns {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
