import React from 'react';

const Spinner = () => {
    return (
         <div className="spinner">
             <div className="lds-dual-ring"></div>

         </div>
    )
}
export default Spinner;