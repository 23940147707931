import React from 'react';
import Location from '../assets/Location.png';
import Shape from '../assets/Shape.png';
import Deis from '../assets/Deis.png';
import {withRouter} from 'react-router-dom';
const InfoMobile  = ({history}) => {
    return (
       <div className="info-mobile">
         <div className="mail" >
         <div className="img-box-m">
        <a href="/particulier#contact">
        <img src={Deis} />
        <p>Contactez nous</p> 
        </a>
        </div>
         </div>
         <div className="phone">
         <div className="img-box-m">
         <a href="tel:0537566809">
         <img src={Shape} />
         <p>Un conseiller</p>
         </a>
         </div>
        
         </div>
         <div className="location"  onClick={() => history.push('/particulier/agences')}>
         <div className="img-box-m">
        <a>
        <img src={Location} />
        <p>Localisation</p>
        </a>
         </div>
         
         </div>
       </div>
    )
}
export default withRouter(InfoMobile);