import React from 'react';
import DrawerComponent from '../components/elements/Drawer';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {Link, withRouter} from 'react-router-dom';
import Facebook from "../assets/Facebook.png";
import LinkedIn from "../assets/LinkedIn.png";
import Instagram from "../assets/Instagram.png";
import Twitter from "../assets/Twitter.png";
import InfoMobile from '../components/InfoMobile';

const HeaderDrawer = ({pathUrl, entreprise, particulier, history, setDrawerOpen}) => {
    return (
        <div className="he-container" onClick={() => setDrawerOpen(false)}>
            <div className="bc"> 
            <div className='top-drawer'>
                <p>Navigation</p>
                <div className="top-drawer-right">
                    <Link to="/entreprise" className={`${pathUrl.includes("entreprise") ? 'deflt' : 'entreprise'}`}>Entreprise</Link>
                    <Link to="/particulier" className={`${pathUrl.includes("particulier") ? 'deflt' : 'particulier'}`}>Particulier</Link>
                </div>
            </div>
         <div className="directory">
             {
                 pathUrl.includes('particulier') ? 
                 
                     particulier.map(el => {
                                                 return(<div className="list-nv" key={el} onClick={() => { history.push(`/particulier/${el}`)}}>
                        <p>{el}</p>
                            <ChevronRightIcon />
                            
                        </div>)

                     })

                 
                 :(
                    entreprise.map(el => {
                        return(<div className="list-nv" key={el} onClick={() => history.push(`/entreprise/${el}`)}>
                        <p>{el}</p>
                            <ChevronRightIcon />
                            
                        </div>)

                     })

                 )
             }
            
            

         </div>
         <div className="top-drawer">
         <p>Langue</p>
                <div className="top-drawer-right">
                    <Link  className="entreprise">Francais</Link>
                    <Link  className="particulier nbn">عربي</Link>
                </div>

         </div>
         <div className="icon-bottom">
             <img src={Facebook} />
             <img src={Instagram} />
             <img src={Twitter} />
             <img src={LinkedIn} />

         </div>
            </div>
            <InfoMobile />
        </div>


    )
}

export default withRouter(HeaderDrawer);