
const INITIAL_STATE = {
    isFetched: false,
    assureData : [],
    errMessage: ''
}
const assureReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
       case 'FETCH_ASSURE_START':
           return {
               ...state,
               isFetched : true
           }
        case 'FETCH_ASSURE_SUCCESS':
            return {
                ...state,
                assureData : action.payload
            }
        case 'FETCH_ASSURE_FAILED':
            return {
                    ...state,
                    errMessage : action.payload
                }
        default:
            return state
    }

}

export default assureReducer;