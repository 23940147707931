import React, {useState } from 'react';
import {Drawer} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';



const useStyles= makeStyles({
    drop: {
      background : '#fff',
      position: 'relative',
      display:'flex',
      flexDirection: 'column',
     alignItems:'flex-end',
     padding:'2em 0',
     
     
    }
  })


  const DrawerComponent = ({ children, handleClose, openState}) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [cartOpen, setCartOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSignModal, setOpenSignModal] = useState(false);
    const [openListener, setOpenListener] = React.useState(false);
    
    const classes  = useStyles();
      return (
         <div >
             <Drawer
             
              
              {...{
                anchor: "top",
                open: openState,
                onClose: handleClose,
              }}
            > 
           
            <div className={classes.drop}>
               {children}
            </div>
    
            </Drawer>
  
         </div>
      )
  }

export default DrawerComponent;