
const INITIAL_STATE = {
    isFetched: false,
    actualitiesData : [],
    errMessage: ''
}
const actualitiesReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
       case 'FETCH_ACTUALITIES_START':
           return {
               ...state,
               isFetched : true
           }
        case 'FETCH_ACTUALITIES_SUCCESS':
            return {
                ...state,
                actualitiesData : action.payload
            }
        case 'FETCH_ACTUALITIES_FAILED':
            return {
                    ...state,
                    errMessage : action.payload
                }
        default:
            return state
    }

}

export default actualitiesReducer;