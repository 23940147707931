
const INITIAL_STATE = {
    mobileResp : false
}


const responsiveReducer = (state = INITIAL_STATE,action) => {
    switch(action.type){
        case 'SET_MOBILE_DESIGN':
            return {
                ...state,
                mobileResp:action.payload

            }
        default:
            return state


    }
}

export default responsiveReducer;