import React, { useState, useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";
import Carousel from "react-multi-carousel";
import styled from "styled-components";
import Icon from '../components/elements/Icon';
import HeroImg1 from "../assets/hero1.png";
import HeroImg2 from "../assets/hero2.png";
import HeroImg3 from "../assets/hero3.png";
import HeroImg4 from "../assets/hero4.png";
import HeroImg5 from "../assets/hero5.png";
import HeroImg6 from "../assets/hero6.png";
import HeroImg7 from "../assets/hero7.png";
import Herobg from "../assets/Herobg.png";
import Bloghero from '../assets/bloghero.svg';
import Location from '../assets/Location.png';
import Deis from '../assets/Deis.png';
import Shape from '../assets/Shape.png';
import HeroBgMobile from "../assets/hero-bg-mobile.png";
import HeroMobile from "../assets/hero-mobile-1.png";
import Spinner from '../components/Spinner';
import{selectCarouselData} from '../redux/carousel/carousel.selectors';
import {connect} from 'react-redux';
import "react-multi-carousel/lib/styles.css";

const Hero = ({history, data}) => {
  const [heroImg, setHeroImg] = useState(HeroImg1);
  const location = useLocation();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1700 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1700, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    switch (location.pathname) {
      case "/entreprise/":
        setHeroImg(HeroImg1);
        break;
      case "/entreprise/produits":
        setHeroImg(HeroImg2);
        break;
      case "/entreprise/agences":
        setHeroImg(HeroImg7);
        break;
      case "/entreprise/services":
        setHeroImg(HeroImg3);
        break;
      case "/particulier/":
        setHeroImg(HeroImg4);
        break;
      case "/particulier/presentation":
        setHeroImg(HeroImg5);
        break;
      case "/particulier/produits":
        setHeroImg(HeroImg6);
        break;
      case "/particulier/agences":
        setHeroImg(HeroImg7);
        break;
      case "/particulier/blog":
        setHeroImg(Bloghero);
        break;
    }
  }, [location.pathname]);
  return (
   
      <Container>
       <div className="container-wrapper">
         
        <div className="wrappers">
        {
          data.length ?
          (
            <div className="wrapper">
      
      <div className="left-side">
   
         {
           [1].map(cr => (
            <div key={cr}>
                  
                  {(() => {
              switch(location.pathname) {
                case '/entreprise/presentation':
                case '/particulier/presentation':
                  return (
                    <>
                    <h1>Bienvenue </h1>
                    <h1>
                 chez <span className="bold">Assurances LYAZIDI</span>
                   </h1>
                    </>
                  )
                case '/particulier/produits':
                  return (
                    <>
                    <h1>Découvrez les produits ASSURANCES LYAZIDI </h1>
                    <h1>
                    dédiés <span className="bold"> aux particuliers</span>
                   </h1>
                    </>                    )
                case '/entreprise/produits':
                  return (
                    <>
                    <h1>Découvrez les produits ASSURANCES LYAZIDI </h1>
                    <h1>
                    dédiés <span className="bold"> aux entreprises</span>
                   </h1>
                    </>
                    )
                    case '/entreprise/services':
                      return (
                        <>
                        <h1>Découvrez les services ASSURANCES LYAZIDI  </h1>
                        <h1>
                        dédiés <span className="bold">aux entreprises</span>
                       </h1>
                        </>

                      )
                    case '/entreprise/agences':
                    case '/particulier/agences':
                      return (
                        <>
                        <h1>Rendez-vous directement  </h1>
                        <h1>
                        à <span className="bold">nos agences !</span>
                       </h1>
                        </>

                        )
                        case '/particulier/CGU':
                        case '/entreprise/CGU':
                          return (
                            <h1>
                            Conditions Générales d’Utilisation et mentions légales De <span className="bold">l’application Mobile </span>
                              </h1>
                          )
                        case '/particulier/Mentions':
                        case '/entreprise/Mentions':
                          return (
                            <h1>
                            Mentions <span className="bold">légales</span>
                              </h1>
                          )
                          case '/particulier/Protections':
                          case '/entreprise/Protections':
                            return (
                              <h1>
                               <span className="bold">La satisfaction </span> clients notre première <span className="bold">préoccupation </span>
                                </h1>
                            )
                          case '/particulier/blog':
                            return (
                              <h1>Blog</h1>
                            )
                    default :
                    return (
                      <h1>
                      <h1>Bienvenue </h1>
                      <h1>
                   chez <span className="bold">Assurances LYAZIDI</span>
                     </h1>
                      </h1>
                    )
              }
            })()} 

{(() => {
              switch(location.pathname) {
                case '/particulier/presentation':
                  return (<p>{data[5].body.replace('<p>', '').replace('</p>', '')}</p>)
                case '/particulier/produits':
                  return (
                    <p>{data[4].body.replace('<p>', '').replace('</p>', '')}</p>
                  )
                case '/entreprise/produits':
                  return (
                    <p>
                   {data[2].body.replace('<p>', '').replace('</p>', '')}                      </p>
                    )
                    case '/entreprise/services':
                      return (
                        <p>
                            {data[1].body.replace('<p>', '').replace('</p>', '')} 
                        </p>

                      )
                    case '/entreprise/agences':
                    case '/particulier/agences':
                      return (
                          <p>
                          {data[3].body.replace('<p>', '').replace('</p>', '')} 
                          </p>

                        )
                      case '/particulier/devis':
                        return (
                          <p>
                            {data[0].body.replace('<p>', '').replace('</p>', '')}
                          </p>
                        )
                      case '/particulier/':
                      
                      case '/':
                        return (
                          <p>
                                        {data[6].body.replace('<p>', '').replace('</p>', '')}

                          </p>
                        )
                        case '/entreprise/':
                        case '/entreprise':
                          case '/entreprise/devis':
                          return (
                            <p>
                              Une panoplie complète de garanties a été conçue pour répondre à vos besoins afin d'assurer vos biens et votre responsabilité mais également protéger votre avenir et celui de vos salariés
                            </p>
                          )
                          case '/entreprise/CGU':
                          case '/particulier/CGU':
                          case '/particulier/Mentions':
                          case '/particulier/Protections':
                         case '/entreprise/Protections':
                         case '/entreprise/Mentions':
                           return (
                             <p>
                            </p>
                           )
                           case '/particulier/blog':
                             return (
                               <p>Découvrez notre blog</p>

                             )
                           
                        default :
                    return (
                      <p>
                        {data[6].body.replace('<p>', '').replace('</p>', '')}
                      </p>
                    )
              }
            })()} 


           
           
          </div>
           ))
         }
     
  
      </div>
      <div className="right-side">
        <img src={heroImg} className="hero-img desktop" />
        <img src={HeroMobile} className="hero-img mobile" />
      </div>
      </div>
          ) : (<Spinner />)
        }
      </div>
        <div className="wrp-ico">
        <Icon imgSrc={Location} history={history}>Localisation</Icon>
        <a className="link_side" href={`${location.pathname.includes("entreprise") ? "/entreprise#contact" : "/particulier#contact"}`}>
             <div className="icon-box">
               <img src={Deis} />
               <span className="icon__title">Contactez-nous</span>
         </div>
         </a>
        <Icon imgSrc={Shape} phone="0537566809">Téléphone</Icon>
        </div>
       </div>
    </Container>

   
  );
};
const mapStateToProps = state => ({
  data : selectCarouselData(state)
})

export default connect(mapStateToProps)(withRouter(Hero));

const Container = styled.div`
  padding: 2em 0;
  background-image: url(${Herobg});
  background-position: center;
  background-size: cover;
  width: 100%;
 
  .wrapper {
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-bottom: 2em;
    
  }
  .right-side{
   display:flex;
   flex-direction:row;
   align-items:center;
   justify-content:center;
   height: 572.91px;
   width: 80%;
  }
  .right-side > img{
    height: 90%;
  }
  
  .left-side {
    max-width: 600px;
    width: 100%;
    padding-right: 2em;
  }
  h1 {
    font-size: 48px;
    color: #fff;
    font-weight: 500;
    font-family:'Gilroy-Regular', sans-serif;
    line-height:58.59px
  }
  .bold {
    font-family:'Gilroy-Bold', sans-serif;
    text-transform:'uppercase';
  }
  p {
    font-size: 20px;
    color: #fff;
    text-align: justify;
    margin-top: 1em;
    font-family:'Gilroy-Light', sans-serif;
    line-height: 30px;
    font-weight: 500;
    
  }
  button {
    background: #fff;
    color: #0390ff;
    padding: 17px 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    margin-top: 3em;
    box-shadow: 0px 5px 5px rgba(3, 8, 40, 0.318359);
    border-radius: 31px;
    font-family:'Gilroy-Bold', sans-serif;
  }
  .mobile {
    display: none;
    object-position: right;
  }
  .react-multi-carousel-dot button {
    width: 20px;
    border-radius: 100px;
    border: none;
    height: 8px;
    padding: 0px !important;
  }
  .react-multi-carousel-dot--active button {
    background: #00dfbb;
    width: 40px;
  }
  .react-multi-carousel-dot-list {
    left: 0;
    justify-content: flex-start;
  }
  .react-multi-carousel-list {
    width: 100%;
    padding: 4em 0;
  }
  .single-item {
    display: none;
  }
  @media only screen and (max-width: 1200px) {
    h1 {
      font-size: 36px;
    }
    p {
      font-size: 16px;
    }
    .left-side {
      max-width: 500px;
     
    }
    .hero-img {
      max-width: 550px;
    }
  }
  @media only screen and (max-width: 992px) {
    height: unset;
    padding: 2em 0;
    margin-top: 51px;
    .hero-img {
      margin: 0em;
      max-height: 250px;
    }
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
      position: absolute;
      right:0;
      height: 100%;
    
    }
   
    .wrapper {
      padding-right: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    h1 {
      font-size: 24px;
    }
    p {
      font-size: 11px;
      line-height:20px;
      
    }
    .left-side {
      max-width: 300px;
    }
  }
  @media only screen and (max-width: 576px) {
    background-image: url(${HeroBgMobile});
    
    
    .wrapper {
      padding-left: 0.5em;
      align-items: flex-start;
    }
    .left-side {
      max-width: 250px;
      padding-right: 0.5em;
    }
    .react-multi-carousel-item {
      width: 200px !important;
    }
    .carousel-container {
      display: none;
    }
    .single-item {
      display: block;
    }
  }
`;