export const fetchAssureStart = () =>({
    type: 'FETCH_ASSURE_START'
});
export const fetchAssureSuccess = (data) =>({
    type: 'FETCH_ASSURE_SUCCESS',
    payload : data

});
export const fetchAssureFailed = (err) =>({
    type: 'FETCH_ASSURE_FAILED',
    payload:err
});