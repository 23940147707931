import React from "react";
import { Grid, Typography } from "@material-ui/core";
import logo from "../assets/logo.png";
import Apple from "../assets/apple.png";
import Play from "../assets/play.png";
import Facebook from "../assets/Facebook.png";
import LinkedIn from "../assets/LinkedIn.png";
import Instagram from "../assets/Instagram.png";
import Twitter from "../assets/Twitter.png";

const FooterMobile = () => {
  return (
    <Grid container className="footer-mobile">
      <Grid item xs={12}>
        <img src={logo} />
      </Grid>
      <Grid item container xs={12} className="footer-mobile-icon">
        <img
          src={Play}
          onClick={() =>
            (window.location.href =
              "https://play.google.com/store/apps/details?id=com.lyazidi")
          }
        />
        <img
          src={Apple}
          onClick={() =>
            (window.location.href =
              "https://apps.apple.com/dz/app/lyazidi-mobile/id1593672793")
          }
        />
      </Grid>
      <Grid item container xs={12} className="footer-mobile-icon-social">
        <img
          src={Facebook}
          onClick={() =>
            (window.location.href =
              "https://www.facebook.com/LyazidiAssurances")
          }
        />
        <img
          style={{ cursor: "pointer" }}
          onClick={() =>
            (window.location.href =
              "https://www.linkedin.com/company/assurance-lyazidi/")
          }
          src={LinkedIn}
        />
      </Grid>
      <Grid item container xs={12} className="first">
        <a href="./Mentions" target="_blank" className="firstList">
          Mentions légales
        </a>
      </Grid>
      <Grid item container xs={12} className="first">
        <a href="./Protections" target="_blank" className="secondList">
          Protection des Données
          <br /> Personnelles
        </a>
      </Grid>
      <Grid item container xs={12} className="first">
        <p className="thirdList">
          2023 © Assurances LYAZIDI Tous droits réservés
        </p>
      </Grid>
    </Grid>
  );
};
export default FooterMobile;
