import React, {useState, useEffect} from 'react';
import {Grid, IconButton} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import Logo from '../assets/logo.png';
import HeaderDrawer from '../components/HeaderDrawer';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';


const HeaderMobile = ({location, history}) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
   
      const pathUrl = location.pathname;
      
      const particulier = ['Accueil', 'Présentation','Produits','Actualites', 'Agences', 'Blog'];
      const entreprise = ['Accueil', 'Présentation','Produits','Actualites',  'Agences', 'Services', 'Blog'];
      useEffect(() => {
          if(drawerOpen){
              document.body.style.overflow="hidden"
          }else{
            document.body.style.overflow="visible"

          }

      }, [drawerOpen])
      
    return (
        <>
         <div className="header-mobile">

<div className="header-child">
    <div className="logo-mobile">
        <img src={Logo} onClick={() => history.push(`/entreprise`)}/>
    </div>
    <div className="menu-it">
   
      
        <div className="menu-lock" onClick={() => window.location.href="https://client.lyazidi.co.ma/login"}>
        
        <p style={{"fontSize": '16px', "textDecoration": "underline"}}>Renouveler votre contrat auto</p>
        </div>
       
        <div className="menu-menu" onClick={() => setDrawerOpen(!drawerOpen)}>
        <div className="menu-bars" >
    <div className={`${drawerOpen ? 'changeOne' : null} bar1`}></div>
    <div className={`${drawerOpen ? 'changeTwo' : null} bar2`}></div>
    <div className={`${drawerOpen ? 'changeThree' : null} bar3`}></div>
  </div>
        <p>MENU</p>
        </div>
    </div>
  
</div>

   </div>
   {
       drawerOpen ? (<HeaderDrawer setDrawerOpen={setDrawerOpen} history={history} entreprise={entreprise} particulier={particulier} pathUrl={pathUrl}/>) : null
   }
   
   
  

        </>
        
    )
}
export default withRouter(HeaderMobile);