import {createSelector} from 'reselect';

const selectCarousel = state => state.carouselReducer 

export const selectCarouselData = createSelector(
    [selectCarousel],
    (carousel) => carousel.data
)
export const selectIsFetched = createSelector(
    [selectCarouselData],
    (data) =>!!data 
)