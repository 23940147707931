const INITIAL_STATE = {
    locations: [
        {
         id: 1,
         title: 'Assurance Lyazidi',
         desc : '33 Avenue Moulay Youssef, Rabat',
         position: [34.0145645,-6.8385563]
        },
        {
            id: 2,
            title: 'Assurance Lyazidi',
            desc : '33 Avenue Moulay Youssef, Rabat',
            position: [34.0147743,-6.8384972]
           },
           {
            id: 3,
            title: 'Assurance Lyazidi',
            desc : 'Rue du Cimetière, Casablanca 20250',
            position: [33.6035652,-7.6448083]
           },
           {
            id: 4,
            title: 'Assurance Lyazidi',
            desc : 'N1, Salé',
            position: [34.0410652,-6.8186546]
           }
 
    ]
}
   

const locationReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        default:
            return state;
    }

}
export default locationReducer;