import { takeLatest, put, call, all } from "redux-saga/effects";
import {
  fetchBlogsStart,
  fetchBlogsSuccess,
  fetchBlogsFailed,
} from "./blogs.actions";
import { BASE_URL } from "../config";
import { getDataFromCollection } from "../../firebase/index";

export function* fetchBlogsStartAsync() {
  try {
    const blogs = yield getDataFromCollection("blogs");
    yield put(fetchBlogsSuccess(blogs));
  } catch (e) {
    yield put(fetchBlogsFailed(e.message));
  }
}
export function* onFetchBlogsStart() {
  yield takeLatest("FETCH_BLOGS_START", fetchBlogsStartAsync);
}
export function* blogsSagas() {
  yield all([call(onFetchBlogsStart)]);
}
