import React from 'react';
import {Grid} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import HeroBg from '../assets/hero-mobile-1.png';
import Spinner from '../components/Spinner';
import {selectCarouselData} from '../redux/carousel/carousel.selectors';
import {connect} from 'react-redux';

const HeroMobile = ({location, data, history}) => {
  console.log(data);
    return (
      <Grid container className="section-hero">
      {
        data.length ? 
        (
          <Grid container item xs={12} className="section-hero-child" >
          <div  className="conts">
           <Grid item container xs={12} className="section-hero-content">
              <Grid item xs={7} className="section-hero-content-left">

              {(() => {
           switch(location.pathname) {
             case '/entreprise/Presentation':
             case '/particulier/Presentation':
               return (
                 <>
                  <p>Bienvenue chez <br />  <span>Assurances LYAZIDI</span></p>
                 </>
               )
             case '/particulier/Produits':
               return (
                 <>
<p>Découvrez les produits ASSURANCES LYAZIDI dédiés<br />  <span>aux particuliers</span></p>                      </>                    )
             case '/entreprise/Produits':
               return (
                 <>
                  <p>Découvrez les produits ASSURANCES LYAZIDI dédiés<br />  <span>aux entreprises</span></p>

                 </>
                 )
                 case '/entreprise/Services':
                   return (
                     <>
                     <p>Découvrez les produits ASSURANCES LYAZIDI dédiés<br />  <span>aux entreprises</span></p>

                     </>

                   )
                 case '/entreprise/Agences':
                 case '/particulier/Agences':
                   return (
                     <>
                      <p>Rendez-vous directement à <br />  <span>nos agences !</span></p>

                     </>

                     )
                 default :
                 return (
                   <>
<p>Bienvenue chez <br />  <span>Assurances LYAZIDI</span></p>
                   </>
                 )
           }
         })()} 
             {(() => {
              switch(location.pathname) {
                case '/particulier/Presentation':
                  return (<p>{data[5].body.replace('<p>', '').replace('</p>', '')}</p>)
                case '/particulier/Produits':
                  return (
                    <p>{data[4].body.replace('<p>', '').replace('</p>', '')}</p>
                  )
                case '/entreprise/Produits':
                  return (
                    <p>
                   {data[2].body.replace('<p>', '').replace('</p>', '')}                      </p>
                    )
                    case '/entreprise/Services':
                      return (
                        <p>
                            {data[1].body.replace('<p>', '').replace('</p>', '')} 
                        </p>

                      )
                    case '/entreprise/Agences':
                    case '/particulier/Agences':
                      return (
                          <p>
                          {data[3].body.replace('<p>', '').replace('</p>', '')} 
                          </p>

                        )
                      case '/particulier/Devis':
                        return (
                          <p>
                            {data[0].body.replace('<p>', '').replace('</p>', '')}
                          </p>
                        )
                      case '/Particulier/':
                      
                      case '/':
                        return (
                          <p>
                                        {data[6].body.replace('<p>', '').replace('</p>', '')}

                          </p>
                        )
                        case '/Entreprise/':
                        case '/Entreprise':
                        case '/entreprise':
                        case '/entreprise/':
                          case '/entreprise/devis':
                          return (

                            <p>
                              Une panoplie complète de garanties a été conçue pour répondre à vos besoins afin d'assurer vos biens et votre responsabilité mais également protéger votre avenir et celui de vos salariés
                            </p>
                          )
                        
                        default :
                    return (
                      <p>
                        {data[6].body.replace('<p>', '').replace('</p>', '')}
                      </p>
                    )
              }
            })()} 

          
               
             
         
          

               
              </Grid>
              <Grid item xs={5} className="section-hero-content-right" >
              <img src={HeroBg} />
              </Grid>

           </Grid>
          </div>
         
      </Grid>
        ) : (<Spinner />)
      }
      </Grid>

    )
}
const mapStateToProps = state => ({
  data : selectCarouselData(state)
})

export default  connect(mapStateToProps)(withRouter(HeroMobile));