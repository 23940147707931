import { takeLatest, put, call, all } from "redux-saga/effects";
import {
  fetchActualitiesFailed,
  fetchActualitiesSuccess,
} from "./actualités.actions";
import { getDataFromCollection } from "../../firebase/index";

export function* fetchActualitiesStartAsync() {
  try {
    const actualities = yield getDataFromCollection("actualites");
    yield put(fetchActualitiesSuccess(actualities));
  } catch (e) {
    yield put(fetchActualitiesFailed(e.message));
  }
}
export function* onFetchActualitiesStart() {
  yield takeLatest("FETCH_ACTUALITIES_START", fetchActualitiesStartAsync);
}
export function* actualitiesSagas() {
  yield all([call(onFetchActualitiesStart)]);
}
