import {call, all} from 'redux-saga/effects';
import {carouselSagas} from './carousel/carousel.sagas';
import {assureSagas} from './assure/assure.sagas';
import {actualitiesSagas} from "./actualités/actualités.sagas";
import {blogsSagas} from "./blogs/blog.sagas";


 function* rootSaga(){
    yield all([call(carouselSagas), call(assureSagas), call(actualitiesSagas), call(blogsSagas)])
}

export default rootSaga