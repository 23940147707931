import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyAy3mN9G3BJnFeQP1D6CzmNUSk3fONO3vc",
  authDomain: "lyazidibo.firebaseapp.com",
  projectId: "lyazidibo",
  storageBucket: "lyazidibo.appspot.com",
  messagingSenderId: "778384837038",
  appId: "1:778384837038:web:aa60f407c6131a85fae03f",
};

// Initialize Firebase
const app = firebase.initializeApp(config);

export const addCollectionAndDocuments = async (
  collectionKey,
  objectsToAdd
) => {
  const collectionRef = firestore.collection(collectionKey);
  const newDoc = collectionRef.doc();
  await newDoc.set({
    date: firebase.firestore.Timestamp.fromDate(new Date()),
    ...objectsToAdd,
  });
  return true;
};

export const getDataFromCollection = async (collectionKey) => {
  const collectionRef = await firestore.collection(collectionKey).get();
  const docs = await collectionRef.docs.map((doc) => doc.data());
  return docs;
};

export const auth = firebase.auth();
export const firestore = firebase.firestore();
