import React from "react";
import styled from "styled-components";
import Facebook from "../assets/Facebook.png";
import LinkedIn from "../assets/LinkedIn.png";
import Instagram from "../assets/Instagram.png";
import Twitter from "../assets/Twitter.png";
import Apple from "../assets/apple.png";
import Play from "../assets/play.png";
import Logo from "../assets/Logo-lyazidi.png";
import { withRouter } from "react-router-dom";

const Footer = ({ history }) => {
  console.log(window.location);
  return (
    <Container>
      <a href="#" className="arrow-top">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 24 24"
          fill="#24DEBC"
        >
          <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" />
        </svg>
      </a>
      <div className="logo-container">
        <img className="logo" src={Logo} />
      </div>
      <div className="icons">
        <a
          href="https://apps.apple.com/dz/app/lyazidi-mobile/id1593672793"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Apple} className="icon" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.lyazidi"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Play} className="icon" />
        </a>
      </div>
      <div className="icons">
        <a
          href=" https://www.facebook.com/LyazidiAssurances"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Facebook} className="icon" />
        </a>
        <a
          href="https://www.linkedin.com/company/assurance-lyazidi/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={LinkedIn} className="icon" />
        </a>
      </div>
      <div className="labels">
        <a href="./Mentions" target="_blank">
          Mentions légales
        </a>
        <a href="./Protections" target="_blank">
          Protection des Données Personnelles
        </a>
      </div>
      <p className="copyright">
        2023 © Assurances LYAZIDI Tous droits réservés
      </p>
      <p className="address">
        ASSURANCES LYAZIDI ET FILS Intermédiaire d'assurance agrée depuis 1954
        et régi par la loi 17-99 portant code des assurances au capital de
        500.000 DHS,siége:16, Av.moulay Youssef - Rabat R.C.22647 I.F 03300364
      </p>
    </Container>
  );
};

export default withRouter(Footer);

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f3f5fa;
  position: relative;

  .logo {
    margin-bottom: 0.5em;
  }
  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5em;
  }
  a {
    margin: 0 1.25em;
  }
  .labels {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5em;
    margin-bottom: 1.5em;
    flex-wrap: wrap;
  }
  a {
    font-size: 18px;
    font-weight: 500;
    color: #183796;
    text-decoration: underline;
    margin: 0 2em 0.8em 0;
    font-family: "Gilroy-Bold", sans-serif;
  }
  .copyright {
    font-size: 15px;
    color: #183796;
    text-align: center;
    font-family: "Gilroy-Medium", sans-serif;
    font-weight: 400;
  }
  .address {
    font-size: 13px;
    font-weight: 300;
    color: #183796;
    text-align: center;
    max-width: 800px;
    margin-top: 0.7em;
    font-family: "Gilroy-Regular", sans-serif;
    margin-bottom: 2em;
  }
  .arrow-top {
    position: absolute;
    display: none;
    width: 48px;
    justify-content: center;
    align-items: center;
    height: 48px;
    top: 7em;
    right: 0em;
    background: #fff;
    border-radius: 100px;
    border: 2px solid #24debc;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  }
  @media only screen and (max-width: 576px) {
    .logo {
      width: 200px;
    }
    .address,
    .labels span:first-child {
      display: none;
    }
    .arrow-top {
      display: flex;
    }
    span {
      font-size: 14px;
    }
    .copyright {
      font-size: 13px;
    }
  }
  .logo-container {
    width: 295px;
  }
  .logo-container > img {
    height: 100%;
  }
`;
