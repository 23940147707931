import React, { useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from "./layouts/DefaultLayout";
import Spinner from "./components/Spinner";
import { connect } from "react-redux";
import { fetchCarouselStart } from "./redux/carousel/carousel.actions";
import { fetchAssureStart } from "./redux/assure/assure.actions";
import { fetchBlogsStart } from "./redux/blogs/blogs.actions";
import { setMobileDesign } from "./redux/responsive/actions";
import CookieConsent from "react-cookie-consent";

import "leaflet/dist/leaflet.css";
import "./styles/main.scss";
const HomeParticulierSpinner = lazy(() => import("./pages/Particulier/Home"));
const DevisParticulierSpinner = lazy(() => import("./pages/Particulier/Devis"));
const AgencesParticulierSpinner = lazy(() =>
  import("./pages/Particulier/Agences")
);
const PresentationParticulierSpinner = lazy(() =>
  import("./pages/Particulier/Presentation")
);
const PresentationEntrepriseSpinner = lazy(() =>
  import("./pages/Entreprise/Presentation")
);
const ProduitsParticulierSpinner = lazy(() =>
  import("./pages/Particulier/Produits")
);
const AgencesEntrepriseSpinner = lazy(() =>
  import("./pages/Entreprise/Agences")
);
const HomeEntrepriseSpinner = lazy(() => import("./pages/Entreprise/Home"));
const ProduitsEntrepriseSpinner = lazy(() =>
  import("./pages/Entreprise/Produits")
);
const ServicesEntrepriseSpinner = lazy(() =>
  import("./pages/Entreprise/Services")
);
const DevisEntrepriseSpinner = lazy(() => import("./pages/Entreprise/Devis"));
const MentionsWithSpinner = lazy(() => import("./pages/Entreprise/Mentions"));
const ProtectionWithSpinner = lazy(() =>
  import("./pages/Entreprise/Protections")
);
const ProduitEntrepriseWithSpinner = lazy(() =>
  import("./components/elements/Produit")
);
const ProduitResEntrepriseWithSpinner = lazy(() =>
  import("./components/elements/ProduitRes")
);
const ProduitPerEntrepriseWithSpinner = lazy(() =>
  import("./components/elements/ProduitPer")
);
const ProduitBienWithSpinner = lazy(() =>
  import("./components/elements/ProduitBen")
);
const BlogWithSpinner = lazy(() =>
  import("./pages/Particulier/Blog.component")
);
const ActualitesWithSpinner = lazy(() =>
  import("./pages/Particulier/Actualites.component")
);
const CGUComponentWithSpinner = lazy(() => import("./pages/Entreprise/CGU"));
const DevisautoWithSpinner = lazy(() => import("./pages/Entreprise/DevisAuto"));
const App = ({
  setMobileDesign,
  fetchCarouselStart,
  fetchAssureStart,
  fetchBlogsStart,
}) => {
  const handleResponsive = () => {
    window.innerWidth <= 900 ? setMobileDesign(true) : setMobileDesign(false);
  };
  useEffect(() => {
    handleResponsive();
    fetchCarouselStart();
    fetchAssureStart();
    fetchBlogsStart();
  }, []);

  window.addEventListener("resize", handleResponsive);
  return (
    <Suspense fallback={<Spinner />}>
      <Router>
        <Layout>
          <Switch>
            <Route
              exact
              path={[
                "/particulier/accueil/",
                "/particulier/accueil",
                "/particulier/",
                "/particulier",
                "/particulier#contact",
              ]}
              component={HomeParticulierSpinner}
            />
            <Route
              exact
              path={("/particulier/devis", "/particulier/devis/")}
              component={DevisParticulierSpinner}
            />
            <Route
              exact
              path={("/particulier/devis-auto", "/particulier/devis-auto/")}
              component={DevisautoWithSpinner}
            />
            <Route
              exact
              path={("/entreprise/devis", "/entreprise/devis/")}
              component={DevisEntrepriseSpinner}
            />
            <Route
              exact
              path={("/particulier/agences", "/particulier/agences/")}
              component={AgencesParticulierSpinner}
            />
            <Route
              exact
              path={("/particulier/présentation", "/particulier/présentation/")}
              component={PresentationParticulierSpinner}
            />
            <Route
              exact
              path={("/particulier/produits", "/particulier/produits/")}
              component={ProduitsParticulierSpinner}
            />
            <Route
              exact
              path={"/particulier/blog"}
              component={BlogWithSpinner}
            />
            <Route
              exact
              path="/particulier/actualites"
              component={ActualitesWithSpinner}
            />
            <Route
              exact
              path={[
                "/entreprise/accueil",
                "/entreprise/accueil/",
                "/entreprise/",
                "/entreprise",
                "/entreprise#contact",
              ]}
              component={HomeEntrepriseSpinner}
            />
            <Route
              exact
              path={("/entreprise/agences", "/entreprise/agences/")}
              component={AgencesEntrepriseSpinner}
            />
            <Route
              exact
              path={("/entreprise/présentation", "/entreprise/présentation/")}
              component={PresentationEntrepriseSpinner}
            />
            <Route
              exact
              path={("/entreprise/produits", "/entreprise/produits/")}
              component={ProduitsEntrepriseSpinner}
            />
            <Route
              exact
              path={("/entreprise/services", "/entreprise/services/")}
              component={ServicesEntrepriseSpinner}
            />
            <Route
              exact
              path={["/entreprise/Mentions", "/particulier/Mentions"]}
              component={MentionsWithSpinner}
            />
            <Route
              exact
              path={["/entreprise/CGU", "/particulier/CGU"]}
              component={CGUComponentWithSpinner}
            />
            <Route
              exact
              path={["/entreprise/Protections", "/particulier/Protections"]}
              component={ProtectionWithSpinner}
            />
            <Route
              exact
              path={"/entreprise/produit/1"}
              component={ProduitEntrepriseWithSpinner}
            />
            <Route
              exact
              path={"/entreprise/produit/2"}
              component={ProduitResEntrepriseWithSpinner}
            />
            <Route
              exact
              path={"/entreprise/produit/3"}
              component={ProduitPerEntrepriseWithSpinner}
            />
            <Route
              exact
              path={"/entreprise/produit/4"}
              component={ProduitBienWithSpinner}
            />
          </Switch>
        </Layout>
      </Router>
    </Suspense>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setMobileDesign: (bool) => dispatch(setMobileDesign(bool)),
  fetchCarouselStart: () => dispatch(fetchCarouselStart()),
  fetchAssureStart: () => dispatch(fetchAssureStart()),
  fetchBlogsStart: () => dispatch(fetchBlogsStart()),
});

export default connect(null, mapDispatchToProps)(App);
