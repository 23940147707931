export const fetchBlogsStart = () =>({
    type: 'FETCH_BLOGS_START'
});
export const fetchBlogsSuccess = (data) =>({
    type: 'FETCH_BLOGS_SUCCESS',
    payload : data

});
export const fetchBlogsFailed = (err) =>({
    type: 'FETCH_BLOGS_FAILED',
    payload:err
});