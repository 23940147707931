import React from 'react';

const Icon = ({imgSrc, phone ,mail, history, children}) =>{
   
    
    return (
        (
            <a className="link_side"  href={`${phone ? `tel:${phone}` : `${mail ? `http://${window.location.hostname}:3000/particulier/#contact` : ''}`}`}  >
             <div className="icon-box" onClick={() => history ? history.push('/particulier/agences') : null}>
               <img src={imgSrc} />
               <span className="icon__title">{children}</span>
         </div>
         </a>
           
        )
    )
}
export default Icon;