import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import "./styles/main.scss";
import "./fonts/Gilroy-Black.ttf";
import "./fonts/Gilroy-Bold.ttf";
import "./fonts/Gilroy-Light.ttf";
import "./fonts/Gilroy-Medium.ttf";
import "./fonts/Gilroy-Regular.ttf";
import CookieConsent from "react-cookie-consent";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <CookieConsent
        buttonText="Accepter"
        style={{ backgroundColor: "#343a40!", zIndex: 3000000 }}
      >
        <div style={{ fontFamily: "Poppins" }}>
          <p
            style={{
              width: "100%",
              fontSize: 15,
              marginBottom: 5,
              lineHeight: 1.7,
              flexDirection: "row",
            }}
          >
            En continuant à utiliser ce site, vous acceptez notre{" "}
            <a
              href="https://lyazidi.co.ma/particulier/Protections"
              style={{ display: "inline", color: "#ffc107" }}
              target="_blank"
            >
              politique de cookies
            </a>
            .
          </p>
        </div>
      </CookieConsent>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
