const INITIAL_STATE = {
    isFetched: false,
    data : [],
    errMessaage : null
}

const carouselReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case 'FETCH_CAROUSEL_START':
            return {
                ...state,
                isFetched : true
            }
        case 'FETCH_CAROUSEL_SUCCESS':
            return {
                ...state,
                data : action.payload

            }
            case 'FETCH_CAROUSEL_FAILED':
                return {
                    ...state,
                    errMessaage : action.payload
                }
            default:
                return state
       
    }
}

export default carouselReducer;