import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import FooterMobile from '../components/FooterMobile';
import HeroMobile from '../components/Heromobile';
import HeaderMobile from '../components/HeaderMobile';
import {selectResponsiveMobile} from '../redux/responsive/selectors';
import { connect } from "react-redux";

const DefaultLayout = ({ children, mobileResp }) => {


  const route = useHistory();

  if (route.location.pathname === "/") {
    route.push("/particulier/");
  }
  
  return (
    <Container>
      <div className="body">
       
        {mobileResp ? (<HeaderMobile />) : ( <Header />)}
        {mobileResp ? (<HeroMobile />) : (<Hero />)}
       
        <div className="content">
          {React.cloneElement(children, { url: location.pathname })}
        </div>
        {mobileResp ? (<FooterMobile />) : (<Footer/>)}
      </div>
      
    </Container>
  );
};

const mapStateToProps = state => ({
  mobileResp : selectResponsiveMobile(state)
})

export default connect(mapStateToProps)(DefaultLayout);

const Container = styled.div``;
