import {combineReducers} from 'redux';
import locationReducer from './Location/reducer';
import positionReducer from './Position/reducer';
import responsiveReducer from './responsive/reducer';
import carouselReducer from './carousel/carousel.reducer';
import assureReducer from './assure/assure.reducer';
import actualitiesReducer from './actualités/actualités.reducer';
import blogsReducer from "./blogs/blogs.reducer";


const rootReducer = combineReducers({
    location : locationReducer,
    positionReducer : positionReducer,
    carouselReducer : carouselReducer,
    responsive: responsiveReducer,
    assure : assureReducer,
    actualities : actualitiesReducer,
    blogs: blogsReducer
})

export default rootReducer;