import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import Menu from "./Menu";
import Facebook from "../assets/Facebook.png";
import LinkedIn from "../assets/LinkedIn.png";
import Instagram from "../assets/Instagram.png";
import Twitter from "../assets/Twitter.png";
import Search from "../assets/Search.png";
import Logo from "../assets/logo.png";

const Header = () => {
  const [menuIsActive, setMenuIsActive] = useState(0);
  const location = useLocation();
  const url = (ur) => {
    if (location.pathname.includes("entreprise")) {
      return `/entreprise/${ur}`;
    } else {
      return `/particulier/${ur}`;
    }
  };

  return (
    <Container>
      <div className="desktop-header">
        <div className="wrappers">
          <div className="top-row row">
            <div className="left-side side">
              <NavLink
                activeClassName="active-link"
                to="/particulier/"
                className="link border-right"
              >
                Particulier
              </NavLink>
              <NavLink
                activeClassName="active-link"
                to="/entreprise/"
                className="link"
              >
                Entreprise
              </NavLink>
            </div>
            <div className="right-side side">
              <a
                href="https://www.facebook.com/LyazidiAssurances"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Facebook} className="icon" />
              </a>
              <a
                href="https://www.linkedin.com/company/assurance-lyazidi/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LinkedIn} className="icon" />
              </a>
              <img src={Search} className="icon borders" />
              <select>
                <option>FR</option>
              </select>
            </div>
          </div>
        </div>
        <div className="wrappers">
          <div className="bottom-row row">
            <Link to="./">
              <img className="logo" src={Logo} />
            </Link>
            <div className="right-side side">
              <NavLink
                exact
                activeClassName="active-link-2 "
                to={url("")}
                className="link-2"
              >
                Accueil
              </NavLink>
              {location.pathname.includes("particulier") ? (
                <NavLink
                  exact
                  activeClassName="active-link-2"
                  to="./actualites"
                  className="link-2"
                >
                  Actualités
                </NavLink>
              ) : null}

              <NavLink
                exact
                activeClassName="active-link-2"
                to={url("présentation")}
                className="link-2"
              >
                Présentation
              </NavLink>
              <NavLink
                exact
                activeClassName="active-link-2"
                to={url("produits")}
                className="link-2"
              >
                Nos produits
              </NavLink>
              <NavLink
                exact
                activeClassName="active-link-2"
                to={url("agences")}
                className="link-2"
              >
                Agences
              </NavLink>
              {location.pathname.includes("entreprise") && (
                <NavLink
                  exact
                  activeClassName="active-link-2"
                  to="./services"
                  className="link-2"
                >
                  Nos services
                </NavLink>
              )}
              {location.pathname.includes("particulier") ? (
                <NavLink
                  exact
                  activeClassName="active-link-2"
                  to="./blog"
                  className="link-2"
                >
                  Blog
                </NavLink>
              ) : null}
              {location.pathname.includes("particulier") ? (
                <a
                  className="espace-btn"
                  href="https://client.lyazidi.co.ma/login"
                >
                  <span className="btn-espace">
                    Renouveler votre contrat auto
                  </span>
                </a>
              ) : null}
            </div>
          </div>
        </div>

        <div className="mobile-header">
          <Link to="./">
            <img className="logo-mobile" src={Logo} />
          </Link>
          <div className="right-side">
            <button className="espace-btn-mobile">
              <svg
                fill="#0390FF"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
              >
                <path d="M10 16c0-1.104.896-2 2-2s2 .896 2 2c0 .738-.404 1.376-1 1.723v2.277h-2v-2.277c-.596-.347-1-.985-1-1.723zm11-6v14h-18v-14h3v-4c0-3.313 2.687-6 6-6s6 2.687 6 6v4h3zm-13 0h8v-4c0-2.206-1.795-4-4-4s-4 1.794-4 4v4zm11 2h-14v10h14v-10z" />
              </svg>
              <span className="menu-span">Espace Client</span>
            </button>
            <button
              className="navbar-toggler"
              onClick={() => setMenuIsActive(!menuIsActive)}
            >
              {!menuIsActive ? (
                <div className="menu-open menu-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    fill="#0390FF"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
                  </svg>

                  <span className="menu-span">Menu</span>
                </div>
              ) : (
                <div className="menu-close menu-icon">
                  <svg
                    fill="#0390FF"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                  </svg>
                  <span className="menu-span">Fermer</span>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
      {menuIsActive ? <Menu url={location.pathname} /> : null}
    </Container>
  );
};

export default Header;

const Container = styled.div`
  width: 100%;

  z-index: 9999;
  background: #f9f9fa;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
    width: 100%;
  }
  .top-row {
    border-bottom: 1px solid #dbdfed;
  }
  .bottom-row {
    padding: 0.5em 0;
  }
  .side {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  select {
    border: none;
    outline: none;
    color: #a7a9ac;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='gray'><path d='M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 4.5px;
    padding-left: 0.25em;
    padding-right: 1.25em;
    font-size: 14px;
    cursor: pointer;
    margin-left: 0.5em;
  }
  .icon {
    margin: 0 0.5em;
    cursor: pointer;
  }
  .borders {
    padding: 0.25em 1em;
    border-left: 1px solid #dbdfed;
    border-right: 1px solid #dbdfed;
  }
  .link {
    font-size: 14px;
    color: #000;
    padding: 0em 0.75em;
  }
  .border-right {
    border-right: 1px solid #dbdfed;
  }
  .active-link {
    color: #0390ff;
    font-weight: 500;
    font-family: "Gilroy-Bold", sans-serif;
  }

  .espace-btn {
    background: #ff992d;
    color: #fff;
    padding: 12px 24px;
    border-radius: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    margin-left: 1em;
    span {
      margin-left: 0.5em;
    }
  }
  .link-2 {
    font-size: 16px;
    color: #000;
    padding: 0.5em 1.5em 1em 1.7em;
    font-weight: 600;
  }
  .active-link-2 {
    color: #0390ff;
    font-weight: 500;
    position: relative;
    font-family: "Gilroy-Bold", sans-serif;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 12%;
      height: 7px;
      background-color: #0390ff;
      border-radius: 100px;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 83%;
      height: 7px;
      background-color: #1cddba;
      border-radius: 100px;
    }
  }
  .mobile-header {
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em;
  }
  .logo-mobile {
    height: 50px;
    padding: 0.5em 0;
  }
  .menu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 48px;
    padding: 0.5em 0;
    padding-left: 1em;
  }
  .espace-btn-mobile {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0.5em 0;
    padding-right: 1em;
    border-right: 1px solid #0390ff;
    svg {
      margin-top: 0.3em;
    }
    span {
      margin-top: 0.3em;
    }
  }
  .right-side {
    display: flex;
    align-items: center;
  }
  .menu-span {
    color: #0390ff;
    font-size: 9px;
  }

  @media only screen and (max-width: 992px) {
    .mobile-header {
      display: flex;
      position: fixed;
      top: 0;
      width: 100vw;
      background: #fff;
    }
    .desktop-header {
      display: none;
    }
  }
`;
