import { takeLatest, put, call, all } from "redux-saga/effects";
import {
  fetchAssureSuccess,
  fetchAssureFailed,
} from "../assure/assure.actions";
import { BASE_URL } from "../config";

export function* fetchAssureStartAsync() {
  try {
    const resp = yield fetch(`${BASE_URL}/drupal_version/api/assure`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/hal+json",
        Authorization: "Basic YWRtaW46JnEzbUhMJSRWU0tSeA==",
      },
    });
    const respJson = yield resp.json();
    yield put(fetchAssureSuccess(respJson));
  } catch (e) {
    yield put(fetchAssureFailed(e.message));
  }
}
export function* onFetchAssureStart() {
  yield takeLatest("FETCH_ASSURE_START", fetchAssureStartAsync);
}
export function* assureSagas() {
  yield all([call(onFetchAssureStart)]);
}
