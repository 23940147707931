export const fetchActualitiesStart = () =>({
    type: 'FETCH_ACTUALITIES_START'
});
export const fetchActualitiesSuccess = (data) =>({
    type: 'FETCH_ACTUALITIES_SUCCESS',
    payload : data

});
export const fetchActualitiesFailed = (err) =>({
    type: 'FETCH_ACTUALITIES_FAILED',
    payload:err
});